import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../navbar";

interface IDashboardLayout {
    title?: string;
}

const DashboardLayout: FC<IDashboardLayout> = ({ children, title }) => {

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div
                className={`min-h-screen w-full bg-PrimaryBlue-OFFBLUE transition-all transform duration-500`}
            >
                <Navbar />
                <div
                    className={`py-8 transition-all transform duration-500 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12`}
                >
                    {children}
                </div>
            </div>
        </>
    );
};

export default DashboardLayout;
