import server from ".";
import { ICustomerImageVerificationParam, IImageVerificationByBVNParam } from "./parameters";
import { IResponse } from "./responses";
import { IFileUploadResponse, IImageVerificationResponse } from "./responses/util";

export const api_get_lgas = async (pagination: Record<string, any>) => {
  const response = await server.get(
    '/repo/localGovernments',
    {
      params: {
        page: pagination?.current || 0,
        size: pagination?.pageSize || 10,
      },
    }
  );
  return response.data;
};

export const api_upload_file = async (file: File) => {
  const payload = new FormData()
  payload.append("file", file)
  const response = await server.post(`/api/v1/file-repo/upload`, payload)

  return response.data as IFileUploadResponse
}

export const api_get_file = async (file_ref: string) => {
  const response = await server.get(
    `/api/v1/file-repo/view/${file_ref}`,
  );

  return response.data;
};

export const api_verify_customer_image = async (payload: ICustomerImageVerificationParam) => {
  const response = await server.post("/api/v1/biometrics/verify-business-owner-image", payload)

  return response.data as IResponse<IImageVerificationResponse>
}

export const api_verify_customer_image_by_BVN = async (payload: IImageVerificationByBVNParam) => {
  const response = await server.post("/api/v1/biometrics/verify-image-by-bvn", payload)

  return response.data as IResponse<IImageVerificationResponse>
}
