import CONFIG_PRODUCTION from "./config-production";
import CONFIG_STAGING from "./config-staging";

type environments = "test" | "dev" | "staging" | "production";

const HOLDER = {
  VERSION: "0.0.1",
  BASE_URL: "https://api.v2.core.loans.moniepoint.development.teamapt.com",
};

const configMap = {
  test: HOLDER,
  dev: HOLDER,
  staging: CONFIG_STAGING,
  production: CONFIG_PRODUCTION,
};

const config =
  configMap[
    (process.env.REACT_APP_ENV as environments)
      ? (process.env.REACT_APP_ENV as environments)
      : "dev"
  ];

export default config;
