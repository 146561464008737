import ArrowSvg from "./Arrow.svg";
import LogoSvg from "./Logo.svg";
import FullLogo from "./FullLogo.svg";
import MoniepointSvg from "./Moniepoint.svg";
import MoniepointDarkSvg from "./MoniepointDark.svg";
import MoniepointLightSvg from "./MoniepointLight.svg";
import NigeriaSvg from "./Nigeria.svg";
import OTPSvg from "./OTP.svg";
import LockSvg from "./Lock.svg";
import Check from "./Check.svg";
import Uncheck from "./Uncheck.svg";
import LetterM from "./M.svg";
import BlueChevronRight from "./BlueChevronRight.svg";
import BlueFunel from "./BlueFunel.svg";
import BlueSearchLens from "./BlueSearchLens.svg";
import GreySearch from "./GreySearch.svg";
import EditDoc from "./EditDoc.svg";
import LocationDefault from "./LocationDefault.svg";
import AvatarWithCam from "./AvatarWithCam.svg";
import BlueCamera from "./BlueCamera.svg";
import BlueGlassess from "./BlueGlassess.svg";
import BlueLightBulb from "./BlueLightBulb.svg";
import BlueEmojiFace from "./BlueEmojiFace.svg";
import BlueCameraVector from "./BlueCameraVector.svg";
import DarkUser from "./DarkUser.svg";
import GreenCircleSuccessCheck from "./GreenCircleSuccessCheck.svg";
import RedCircleErrorExclam from "./RedCircleErrorExclam.svg";
import GreenEditDoc from "./GreenEditDoc.svg";
import GreenLocation from "./GreenLocation.svg";
import RedEditDoc from "./RedEditDoc.svg";
import RedLocation from "./RedLocation.svg";
import PlainAvatarBlueHead from "./PlainAvatarBlueHead.svg";
import AltBlueAvatar from "./AltBlueAvatar.svg";
import BlueGrayHouseVector from "./BlueGrayHouseVector.svg";
import BlueHouseVector from "./BlueHouseVector.svg";
import OrangeBriefCase from "./OrangeBriefCase.svg";
import PurpleAvatar from "./PurpleAvatar.svg";
import GreyEyeIcon from "./GreyEyeIcon.svg";
import WhiteNaira from "./WhiteNaira.svg";
import * as DashboardIcons from "./dashboard-icons";
import * as SidebarIcons from "./sidebar-icons";

export const Icons = {
  LockSvg,
  ArrowSvg,
  LogoSvg,
  FullLogo,
  MoniepointSvg,
  MoniepointDarkSvg,
  MoniepointLightSvg,
  NigeriaSvg,
  OTPSvg,
  Check,
  Uncheck,
  LetterM,
  BlueChevronRight,
  BlueFunel,
  BlueSearchLens,
  GreySearch,
  LocationDefault,
  AvatarWithCam,
  EditDoc,
  BlueCamera,
  BlueGlassess,
  BlueLightBulb,
  BlueEmojiFace,
  BlueCameraVector,
  DarkUser,
  GreenCircleSuccessCheck,
  RedCircleErrorExclam,
  GreenEditDoc,
  GreenLocation,
  RedEditDoc,
  RedLocation,
  PlainAvatarBlueHead,
  AltBlueAvatar,
  BlueGrayHouseVector,
  BlueHouseVector,
  OrangeBriefCase,
  PurpleAvatar,
  GreyEyeIcon,
  WhiteNaira,
  ...DashboardIcons,
  ...SidebarIcons,
};

export default Icons;
