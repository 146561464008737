import { IDispatch } from "../helper";
import { ISettings, ESettings } from "./types";

export const setLoader =
  (dispatch: IDispatch<ISettings>) => async (value: boolean) => {
    try {
      dispatch({ type: ESettings.SET_LOADER, pageLoading: value });
    } catch (error) {
      dispatch({
        type: ESettings.REJECTED,
        pageLoading: false,
      });
    }
  };

