import server from "./index";
import {
    IUserActivate,
    IUserLogin,
    IUserReset,
} from "./parameters/user";
import { ILoginResponse } from "./responses/auth";
import { IResponse } from "./responses";

export const api_login = async (params: IUserLogin) => {
    const response = await server.post(
        "/api/v1/auth/login",
        params
    );
    return response.data as ILoginResponse;
};

export const api_activate = async <T>(params: IUserActivate) => {
    const response = await server.post("/users/activate", params);
    return response.data as T;
};

export const api_initiate_reset = async (username: string) => {
    const response = await server.post(
        "/user-management/initiate-password-reset",
        { username }
    );
    return response.data as IResponse<{}>;
};

export const api_reset_password = async (params: IUserReset) => {
    const response = await server.post(
        "/user-management/reset-password",
        params
    );
    return response.data as IResponse<{}>;
};

