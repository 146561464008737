export enum SubtaskTypes {
    BUSINESS_OWNER_VERIFICATION = "BUSINESS_OWNER_VERIFICATION",
    BUSINESS_VERIFICATION = "BUSINESS_VERIFICATION",
    CODEBTOR_VERIFICATION = "CODEBTOR_VERIFICATION",
    GUARANTOR_VERIFICATION = "GUARANTOR_VERIFICATION",
}

export enum SubtaskVerificationsStatus {
    PENDING= "PENDING",
    CONFIRMED_TRUE = "CONFIRMED_TRUE",
    CONFIRMED_FALSE = "CONFIRMED_FALSE",
}

export interface OnImageCapturedProps {
    coords: GeolocationCoordinates
    imageFile: File
    onVerifPassed(): void 
    onVerifFailed(): void
}

export enum GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE {
    IMAGE_VERIFICATION = "IMAGE_VERIFICATION",
    CONFIRM_OCCUPATION = "CONFIRM_OCCUPATION",
    AGREEMENT_EXECUTION = "AGREEMENT_EXECUTION"
}

export enum BUSINESS_VERIFICATION_TYPE {
    CONFIRM_ADDRESS = "CONFIRM_ADDRESS",
    CONFIRM_OPERATIONS = "CONFIRM_OPERATIONS",
}

export enum BUSINESS_OWNER_VERIFICATION_TYPE {
    IMAGE_VERIFICATION = "IMAGE_VERIFICATION",
    CONFIRM_ADDRESS = "CONFIRM_ADDRESS",
    AGREEMENT_EXECUTION = "AGREEMENT_EXECUTION"
}

export enum VerifTaskState {
    PASSED = "PASSED",
    FAILED = "FAILED",
    SUBMITTED = "SUBMITTED",
    ABORTED = "ABORTED",
    PENDING = "PENDING",
}
