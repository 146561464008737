import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import DashboardLayout from "views/components/layouts/dashboard";
import { DataLoader } from "views/components/loaders";
import { dashBoardPaths } from "./paths";




/* Dashboard Routing */
const SubTask = React.lazy(
  () => import("views/modules/dashboard/subtaskPage")
)
const SubTasks = React.lazy(
  () => import("views/modules/dashboard/subTasks")
)
const CRAJobs = React.lazy(
  () => import("views/modules/dashboard/CRAJobs")
)
const Wallet = React.lazy(
  () => import("views/modules/dashboard/wallet")
)
const NotFound = React.lazy(
  () => import("views/modules/dashboard/not-found")
);
const Logout = React.lazy(() => import("views/modules/dashboard/logout"));

export const DashboardRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <DashboardLayout>
        <React.Suspense fallback={<DataLoader text="loading" />}>
          <Outlet />
        </React.Suspense>
      </DashboardLayout>
    ),
    children: [
      {path: dashBoardPaths.subRoutes.CRAJobs, element: <CRAJobs />},
      { path: dashBoardPaths.subRoutes.subTasks, element: <SubTasks /> },
      { path: dashBoardPaths.subRoutes.subtaskPage, element: <SubTask />},
      { path: dashBoardPaths.subRoutes.wallet, element: <Wallet />},
      { path: dashBoardPaths.subRoutes.logout, element: <Logout /> },
      { path: "*", element: <NotFound /> },
      { path: "", element: <NotFound /> },
    ],
  },
];
