import { ESettings, ISettings } from "./types";

export const SettingsReducer = (
  state: ISettings,
  action: ISettings
): ISettings => {
  switch (action.type) {
    case ESettings.SET_LOADER:
      return {
        ...state,
        pageLoading: action.pageLoading,
      };
    case ESettings.REJECTED:
      return {
        ...state,
        pageLoading: false,
      };
    default:
      return state;
  }
};
