export const SETTINGS_STATE: ISettings = {
  pageLoading: false,
};

export interface ISettings {
  pageLoading: boolean;
  type?: ESettings;
}

export enum ESettings {
  SET_LOADER = "SET_LOADER",
  REJECTED = "REJECTED",
}

export type ISettingsContext = {
  state: ISettings;
  setLoader: (t: boolean) => any;
};
