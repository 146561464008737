import numeral from "numeral";
import format from "date-fns/format";
import { FormState } from "react-hook-form";

export const parseError = (error: any) => {
  try {
    if (error.response && error.response.status >= 500) {
      return 'System error!'
    }
    if (error.response && error.response.data && error.response.data.error) {
      return error.response.data.error
    }
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message
    }
    if (error.response && error.response.data && error.response.data.errors) {
      if (error.response.data.errors.length > 0) {
        return error.response.data.errors[0].message;
      }
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.responseMessage
    ) {
      return error.response.data.responseMessage;
    }

    return error.message;
  } catch (err) {
    return "Sorry, service failed to process your request please try again";
  }
};

export const saveSession = ({
  accessToken,
  expiresIn,
}: {
  accessToken: string;
  expiresIn: number;
}) => {
  localStorage.setItem("accessToken", accessToken as string);
  localStorage.setItem("login_time", JSON.stringify(new Date().getTime()));
  localStorage.setItem("expiresIn", expiresIn.toString());
};

export const isObjectEmpty = (obj: object) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const validateObjectFields = (obj: any) => {
  let err = "";
  for (let item in obj) {
    if (!!obj[item] === false) {
      err += item + ` is required\n`;
    }
  }

  if (!!err) throw Error(err);
};

export const trimObject = (obj: any) =>
  Object.keys(obj)
    .filter((k) => obj[k] !== "")
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});

export const parseJwt = (token: string) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const getErrorMessage = (field: string, formState: FormState<any>) => {
  return formState.errors[field] && formState.errors[field].message;
};

export const getDecimals = (num: number) =>
  numeral(num).format("0,0.00").split(".")[1];

export const formatDate = (date: string) =>
  `${format(new Date(date), "KK:mmaaa")} | ${format(
    new Date(date),
    "d MMM yyyy"
  )}`;

