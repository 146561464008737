export const dashBoardPaths = {
    base: "/dashboard",
    subRoutes: {
        CRAJobs: "/cra-jobs",
        subTasks: "/cra-jobs/:id",
        subtaskPage: "/subtasks/:id",
        wallet: "/wallet",
        logout: "/logout",
    },
}

export const getStaticPathString = (path: string) => path.split(":")[0]
