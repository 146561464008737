import { parseError } from "helpers";
import { convertToPaginatedData, INIT_PAGE_DATA } from "helpers/pagination";
import { BUSINESS_OWNER_VERIFICATION_TYPE, BUSINESS_VERIFICATION_TYPE, GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE, SubtaskTypes } from "helpers/types";
import { toast } from "react-toastify";
import { api_get_file, api_get_lgas, api_upload_file, api_verify_customer_image, api_verify_customer_image_by_BVN } from "server/api";
import {
    api_abort_subtask,
    api_approve_or_reject_CRA_job,
    api_get_CRA_jobs,
    api_get_CRA_job_details,
    api_get_subtask,
    api_submit_business_owner_verifications,
    api_submit_business_verifications,
    api_submit_codebtor_verifications,
    api_submit_guarantor_verifications,
    api_submit_subtask,
} from "server/creditRiskAssessment";
import {
    ICustomerImageVerificationParam,
    IImageVerificationByBVNParam,
} from "server/parameters";
import {
    IAbortSubtaskParam,
    IApproveOrRejectCRAJobParam,
} from "server/parameters/CRA";
import { IFCRAJobsFilters } from "server/parameters/formFilters";
import { IResponse } from "server/responses";
import { IFileUploadResponse, IImageVerificationResponse } from "server/responses/util";
import { IDispatch } from "../helper";
import { IStore, EStore } from "./types";



export const setGenericActionLoading =
    (dispatch: IDispatch<IStore>) => async (key: string, status: boolean) => {
        try {
            dispatch({
                type: EStore.SET_GENERIC_ACTION_LOADING,
                actionsLoadingState: { [key]: status }
            })
        } catch (error) {
            dispatch({
                type: EStore.SET_GENERIC_ACTION_LOADING,
                actionsLoadingState: { [key]: false }
            })
        }
    }

export const setCRAJobsSearchParams =
    (dispatch: IDispatch<IStore>) => async (searchParams: Partial<IFCRAJobsFilters>) => {
        try {
            dispatch({
                type: EStore.SET_CRA_JOBS_SEARCH_PARAMS,
                CRAJobsSearchParams: searchParams
            })
        } catch (error) {
            dispatch({
                type: EStore.REJECTED,
            });
        }
    }

export const uploadFile =
    (dispatch: IDispatch<IStore>) => async (file: File, showNotif?: boolean, cb?: (data: IFileUploadResponse) => void) => {
        try {
            setGenericActionLoading(dispatch)(file.name, true)

            const uploadData = await api_upload_file(file)

            setGenericActionLoading(dispatch)(file.name, false)
            showNotif && toast.success("File uploaded successfully!")
            if (cb) {
                return cb(uploadData)
            } else {
                return uploadData
            }
        } catch (error) {
            setGenericActionLoading(dispatch)(file.name, false)
            showNotif && toast.error(parseError(error))
            return null
        }
    }

export const fetchFile = (dispatch: IDispatch<IStore>) =>
    async ({
        file_ref, cb, fileExtension, loadingKey
    } : {file_ref: string, cb: (data: any) => any, fileExtension?: string, loadingKey?: string}) => {
    try {
        if (loadingKey) setGenericActionLoading(dispatch)(loadingKey, true)

        const data = await api_get_file(file_ref)
        var base64Image = Buffer.from(data, 'binary').toString('base64')
        const fileUrl = (fileExtension || 'data:image/jpeg;base64,') + base64Image
        cb(fileUrl)
        if (loadingKey) setGenericActionLoading(dispatch)(loadingKey, false)
    } catch (error) {
        if (loadingKey) setGenericActionLoading(dispatch)(loadingKey, false)
    }
}

export const verifyCustomerImage = (dispatch: IDispatch<IStore>) =>
    async (payload: ICustomerImageVerificationParam, cb?: (data: IResponse<IImageVerificationResponse>) => void) => {
        try {
            const data = await api_verify_customer_image(payload)

            if (cb) {
                return cb(data)
            } else {
                return data
            }
        } catch (error) {
            dispatch({
                type: EStore.REJECTED,
            });
        }
    }

export const verifyCustomerImageByBVN = (dispatch: IDispatch<IStore>) =>
    async (payload: IImageVerificationByBVNParam, cb?: (data: IResponse<IImageVerificationResponse>) => void) => {
        try {
            const data = await api_verify_customer_image_by_BVN(payload)

            if (cb) {
                return cb(data)
            } else {
                return data
            }
        } catch (error) {
            dispatch({
                type: EStore.REJECTED,
            });
        }
    }

export const approveOrRejectCRAJob = (dispatch: IDispatch<IStore>) => async (payload: IApproveOrRejectCRAJobParam) => {
    try {
        setGenericActionLoading(dispatch)("CRAJobApprovalOrRejectionLoading", true)

        await api_approve_or_reject_CRA_job(payload)

        setGenericActionLoading(dispatch)("CRAJobApprovalOrRejectionLoading", false)
        toast.success(
            payload.approved
            ? "Assessment approved successfully"
            : "Assessment rejected successfully"
        )
    } catch (error) {
        setGenericActionLoading(dispatch)("CRAJobApprovalOrRejectionLoading", false)
        toast.error(parseError(error))
    }
}

export const getCRAJobs = (dispatch: IDispatch<IStore>) => async (searchParams = {}, paginationData = {}) => {
    try {
        setGenericActionLoading(dispatch)("CRAJobsLoading", true)
        const { responseBody } = await api_get_CRA_jobs(searchParams, paginationData)

        const { data, pagination } = convertToPaginatedData(responseBody)
        dispatch({ type: EStore.SET_CRA_JOBS, CRAJobs: { data, pagination } })
        setGenericActionLoading(dispatch)("CRAJobsLoading", false)
    } catch (error) {
        setGenericActionLoading(dispatch)("CRAJobsLoading", false)
        toast.error(parseError(error))
        dispatch({ type: EStore.SET_CRA_JOBS, CRAJobs: INIT_PAGE_DATA })
    }
}

export const getCRAJobDetails = (dispatch: IDispatch<IStore>) => async (CRA_Id: number | string) => {
    try {
        setGenericActionLoading(dispatch)("subtasksLoading", true)

        const { responseBody } = await api_get_CRA_job_details(CRA_Id)
        dispatch({ type: EStore.SET_CRA_JOB_DETAILS, CRAJobDetails: responseBody })

        setGenericActionLoading(dispatch)("subtasksLoading", false)
    } catch (error) {
        setGenericActionLoading(dispatch)("subtasksLoading", false)
        toast.error(parseError(error))
        dispatch({ type: EStore.SET_CRA_JOB_DETAILS, CRAJobDetails: null })
    }
}

export const getSubtask =
    (dispatch: IDispatch<IStore>) => async (id: string, type: SubtaskTypes) => {
        try {
            setGenericActionLoading(dispatch)("subtaskLoading", true)

            const data = await api_get_subtask(id, type)

            dispatch({ type: EStore.SET_SUBTASK, subtask: data?.responseBody })

            setGenericActionLoading(dispatch)("subtaskLoading", false)
        } catch (error) {
            dispatch({ type: EStore.SET_SUBTASK, subtask: null })
            toast.error(parseError(error))
            setGenericActionLoading(dispatch)("subtaskLoading", false)
        }
    }

export const setSubtaskParams =
    (dispatch: IDispatch<IStore>) => async (data: Record<string, any>) => {
        try {
            dispatch({ type: EStore.SET_SUBTASK_PARAMS, subtaskParams: data })
        } catch (error) {
            dispatch({
                type: EStore.REJECTED,
            });
            toast.error(parseError(error))
        }
    }

export const submitSubtask = (dispatch: IDispatch<IStore>) => async<T>(data: T, type: SubtaskTypes) => {
    try {
        setGenericActionLoading(dispatch)("subtaskSubmissionLoading", true)

        await api_submit_subtask(data, type)

        setGenericActionLoading(dispatch)("subtaskSubmissionLoading", false)
        return { status: true }
    } catch (error) {
        setGenericActionLoading(dispatch)("subtaskSubmissionLoading", false)
        toast.error(parseError(error))
        return { status: false }
    }
}

export const submitGuarantorOrCodebtorVerifications =
    (dispatch: IDispatch<IStore>) =>
    async<T>(data: T, type: GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE, subtaskType: SubtaskTypes) => {
    try {
        setGenericActionLoading(dispatch)("subtaskVerificationsLoading", true)

        if (subtaskType === "CODEBTOR_VERIFICATION") {
            await api_submit_codebtor_verifications(data, type)
            setGenericActionLoading(dispatch)("subtaskVerificationsLoading", false)
            return { status: true }
        } else if (subtaskType === "GUARANTOR_VERIFICATION") {
            await api_submit_guarantor_verifications(data, type)
            setGenericActionLoading(dispatch)("subtaskVerificationsLoading", false)
            return { status: true }
        }
    } catch (error) {
        setGenericActionLoading(dispatch)("subtaskVerificationsLoading", false)
        toast.error(parseError(error))
        return { status: false }
    }
}

export const submitBusinessOwnerVerifications =
    (dispatch: IDispatch<IStore>) =>
    async<T>(data: T, type: BUSINESS_OWNER_VERIFICATION_TYPE) => {
    try {
        setGenericActionLoading(dispatch)("subtaskVerificationsLoading", true)

        await api_submit_business_owner_verifications(data, type)

        setGenericActionLoading(dispatch)("subtaskVerificationsLoading", false)
        return { status: true }
    } catch (error) {
        setGenericActionLoading(dispatch)("subtaskVerificationsLoading", false)
        toast.error(parseError(error))
        return { status: false }
    }
}

export const submitBusinessVerifications =
    (dispatch: IDispatch<IStore>) =>
    async<T>(data: T, type: BUSINESS_VERIFICATION_TYPE) => {
    try {
        setGenericActionLoading(dispatch)("subtaskVerificationsLoading", true)

        await api_submit_business_verifications(data, type)

        setGenericActionLoading(dispatch)("subtaskVerificationsLoading", false)
        return { status: true }
    } catch (error) {
        setGenericActionLoading(dispatch)("subtaskVerificationsLoading", false)
        toast.error(parseError(error))
        return { status: false }
    }
}

export const abortSubtask = (dispatch: IDispatch<IStore>) => async (data: IAbortSubtaskParam) => {
    try {
        setGenericActionLoading(dispatch)("abortingSubtask", true)

        await api_abort_subtask(data)

        setGenericActionLoading(dispatch)("abortingSubtask", false)
    } catch (error) {
        setGenericActionLoading(dispatch)("abortingSubtask", false)
        toast.error(parseError(error))
    }
}

export const getLGAs = (dispatch: IDispatch<IStore>) => async (pagination: Record<string, any>) => {
    try {
        const data = await api_get_lgas(pagination);
        dispatch({
            type: EStore.SET_LGAs,
            localGovernments: data.content,
        });
    } catch (error) {
        dispatch({
            type: EStore.REJECTED,
        });
    }
};
