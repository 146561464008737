import { EUser, IUserStore, USER_STATE } from "./types";

const UserReducer = (state: IUserStore, action: IUserStore) => {
  switch (action.type) {
    case EUser.REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case EUser.SAVE:
      return {
        ...state,
        user: action.user,
        is_authenticated: true,
        loading: false,
        error: null,
      };
    case EUser.SET_ERROR_MESSAGE:
      return {
        ...state,
        error: action.error,
      };
    case EUser.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EUser.PENDING:
      return { ...state, loading: true, error: null };
    case EUser.LOGOUT:
      return { ...USER_STATE, error: action.error };
    default:
      return state;
  }
};

export default UserReducer;
