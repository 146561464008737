import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Loading } from "views/components/loaders";
import Auth from "./auth";
import Dashboard from "./dashboard";


const Modules = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/auth/*" element={<Auth />} />
                    <Route path="/dashboard/*" element={<Dashboard />} />
                    <Route path="*" element={<Auth />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default Modules
