import React from "react";
import { RiLoader4Line } from "react-icons/ri";
import logo from "../../assets/svg/Logo.svg";

export const Loading = () => (
  <div className="h-screen w-screen top-0 bg-black/40 fixed z-loaderLevel1 flex justify-center items-center">
    <img
      src={logo}
      className="animate-pulse transform transition-all select-none"
      alt="Logo"
    />
  </div>
);

interface IDataLoader {
  text?: string;
  size?: "sm" | "lg";
}
export const DataLoader = ({ text, size }: IDataLoader) => {
  return (
    <div
      className={`${
        size === "sm" ? "" : "h-screen fixed z-loaderLevel1"
      } w-full top-0 bg-white/80 flex flex-col justify-center items-center`}
    >
      <RiLoader4Line className="animate-spin text-grey-DARKSPELL" size={34} />
      <p className="text-lg font-bold text-PrimaryBlue text-center">{text}</p>
    </div>
  );
};
