import { INIT_PAGE_DATA, IPaginatedData } from "helpers/pagination";
import { BUSINESS_OWNER_VERIFICATION_TYPE, BUSINESS_VERIFICATION_TYPE, GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE, SubtaskTypes } from "helpers/types";
import { ICustomerImageVerificationParam, IImageVerificationByBVNParam } from "server/parameters";
import { IAbortSubtaskParam, IApproveOrRejectCRAJobParam } from "server/parameters/CRA";
import { IResponse } from "server/responses";
import { ICreditRiskAssessmentJob, ICreditRiskAssessmentJobDetails, } from "server/responses/creditRiskAssessment";
import { IFileUploadResponse, IImageVerificationResponse, ILocalGovernment } from "server/responses/util";

export const STORE_STATE: IStore = {
  CRAJobs: INIT_PAGE_DATA,
  CRAJobsSearchParams: {},
  CRAJobDetails: null,
  subtask: null,
  subtaskParams: {},
  actionsLoadingState: {},
  localGovernments: [],
};

export interface IStore {
  type?: EStore;
  CRAJobs: IPaginatedData<ICreditRiskAssessmentJob>;
  CRAJobsSearchParams: Record<string, any>;
  CRAJobDetails: ICreditRiskAssessmentJobDetails | null;
  subtask: any;
  subtaskParams: any;
  actionsLoadingState: Record<string, boolean>;
  localGovernments: ILocalGovernment[];
}

export enum EStore {
  SET_GENERIC_ACTION_LOADING = "SET_GENERIC_ACTION_LOADING",
  SET_CRA_JOBS = "SET_CRA_JOBS",
  SET_CRA_JOB_DETAILS = "SET_CRA_JOB_DETAILS",
  SET_CRA_JOBS_SEARCH_PARAMS = "SET_CRA_JOBS_SEARCH_PARAMS",
  SET_SUBTASK = "SET_SUBTASK",
  SET_SUBTASK_PARAMS = "SET_SUBTASK_PARAMS",
  SET_LGAs = "SET_LGAs",
  REJECTED = "REJECTED",
}

export type IStoreContext = {
  state: IStore;
  setCRAJobsSearchParams: (searchParams: Record<string, any>) => any;
  getCRAJobs: (searchParams?: Record<string, any>, pagination?: Record<string, any>) => any;
  approveOrRejectCRAJob: (payload: IApproveOrRejectCRAJobParam) => Promise<any>;
  getCRAJobDetails: (CRA_Id: string) => Promise<any>;
  getSubtask: (id: string, type: SubtaskTypes) => Promise<any>;
  setSubtaskParams: (data: Record<string, any>) => any
  submitSubtask: <T>(data: T, type: SubtaskTypes) => Promise<{status: boolean}>
  abortSubtask: (data: IAbortSubtaskParam) => Promise<any>
  submitGuarantorOrCodebtorVerifications:
    <T>(data: T, type: GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE, subtaskType: SubtaskTypes) => Promise<{status: boolean}>
  submitBusinessOwnerVerifications:
  <T>(data: T, type: BUSINESS_OWNER_VERIFICATION_TYPE) => Promise<{status: boolean}>
  submitBusinessVerifications:
  <T>(data: T, type: BUSINESS_VERIFICATION_TYPE) => Promise<{status: boolean}>
  uploadFile: (file: File, showNotif?: boolean, cb?: (data: IFileUploadResponse) => void) => Promise<IFileUploadResponse | null>
  fetchFile: (prop: {file_ref: string, cb: (data: any) => any, fileExtension?: string, loadingKey?: string}) => Promise<any>
  verifyCustomerImage: (data: ICustomerImageVerificationParam, cb?: (data: IResponse<IImageVerificationResponse>) => void) => Promise<any>;
  verifyCustomerImageByBVN: (data: IImageVerificationByBVNParam, cb?: (data: IResponse<IImageVerificationResponse>) => void) => Promise<any>;
  getLGAs: (pagination: Record<string, any>) => Promise<void>;
  setGenericActionLoading: (key: string, status: boolean) => any;
};
