export interface IPaginatedData<T> {
  data: T[]
  pagination: {
    current: number,
    pageSize: number,
    showSizeChanger: boolean,
    total: number,
    totalPages: number,
    numberOfElements: number,
    pageSizeOptions: string[],
  }
}

export const INIT_PAGE_DATA = {
  data: [],
  pagination: {
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
    total: 0,
    totalPages: 0,
    numberOfElements: 0,
    pageSizeOptions: ['5', '10', '20', '30', '40'],
  },
}



export const convertToPaginatedData = (responseBody: any) => {
  const { size, totalElements, totalPages, numberOfElements, number } = responseBody

  return {
    data: responseBody.content,
    pagination: {
      current: number,
      pageSize: size,
      showSizeChanger: true,
      total: totalElements,
      totalPages,
      numberOfElements,
      pageSizeOptions: ['5', '10', '20', '30', '40'],
    },
  }

  // return INIT_PAGE_DATA
}
