import React, { useContext } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { Loading } from "views/components/loaders";
import { AuthRoutes } from "routes/auth/routes";
import { UserContext } from "context/user";
import { IUserContext } from "context/user/types";
import { useEffect } from "react";
import { useMemo } from "react";

const Auth = () => {
  const { state }: IUserContext = useContext(UserContext);
  const elements = useRoutes(AuthRoutes);

  let navigate = useNavigate();

  useEffect(() => {
    const { user, is_authenticated } = state;
    if (user && is_authenticated) {
      //route to authenticated pages
      navigate("/dashboard/cra-jobs");
    }
  }, [state, navigate]);

  return useMemo(
    () => <React.Suspense fallback={<Loading />}>{elements}</React.Suspense>,
    [elements]
  );
};

export default Auth;
