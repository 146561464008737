import { SettingsContext } from "context/settings";
import { ISettingsContext } from "context/settings/types";
import { UserContext } from "context/user";
import { IUserContext } from "context/user/types";
import React from "react";
import { useMemo } from "react";
import { useContext } from "react";
import Icons from "views/assets/svg";

const Navbar = () => {
  const { logout }: IUserContext = useContext(UserContext);
  const {
    setLoader,
  }: ISettingsContext = useContext(SettingsContext);

  const handleLogout = () => {
    setLoader(true);
    setTimeout(() => setLoader(false), 500);
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return useMemo(
    () => (
      <nav
        className=
          "flex items-center h-20 w-full justify-between bg-white border-t-4 border-t-PrimaryBlue border-b-2 border-b-PrimaryBlue-LIGHT px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12"
          >
          <div className="flex items-center space-x-4">
            <img src={Icons.FullLogo} alt="Logo Icon" className="cursor-pointer" />
          </div>
        <div className="flex space-x-4">
          <img
            src={Icons.DarkUser}
            alt="User Icon"
            className="cursor-pointer "
          />
          <img
            src={Icons.Logout}
            alt="Logout Icon"
            className="cursor-pointer"
            onClick={handleLogout}
          />
        </div>
      </nav>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export default Navbar;
