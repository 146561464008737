import * as React from "react"

function SvgScript(props) {
  return (
    <svg
      width={162}
      height={100}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M134.207 14.969V100H69.914c-6.3 0-11.673-3.54-12.846-8.758-.124-.683-.248-1.428-.248-2.112V71.43c0-18.386-.061-45.032-.061-54.41v-2.671-.808c0-.124 0-.31-.062-.434-.494-5.777-4.262-10.497-9.388-12.299C46.074.373 44.715.062 43.356 0h-.061 76.09c8.152 0 14.822 6.708 14.822 14.969z"
        fill="#EFEFEF"
      />
      <path
        d="M162 84.969c0 8.26-6.67 14.969-14.884 14.969H73.187c6.176-1.553 10.808-7.143 10.808-13.851v-1.118H162z"
        fill="url(#prefix__paint0_linear_41:2516)"
      />
      <path
        d="M56.82 71.367H14.205c.927 0 1.853-.063 2.718-.249 6.546-1.242 11.487-7.08 11.487-13.975V14.286c0-7.143 5.25-13.106 12.105-14.1A10.818 10.818 0 0142.554 0h.74c1.36.062 2.718.31 3.953.807 5.188 1.802 8.956 6.522 9.45 12.174.062.435.062.808.062 1.243v55.403c.061.56.061 1.18.061 1.74z"
        fill="url(#prefix__paint1_linear_41:2516)"
      />
      <path
        opacity={0.42}
        d="M56.82 71.367h-4.2c0-20.684-.061-51.74-.061-57.081v-.808c0-.124 0-.31-.062-.434C52.003 7.39 48.236 2.67 43.11.87 42.245.559 41.38.373 40.515.186A10.818 10.818 0 0142.554 0h.74c1.36.062 2.718.31 3.953.807 5.188 1.802 8.956 6.522 9.45 12.174.062.435.062.808.062 1.243v55.403c.061.56.061 1.18.061 1.74z"
        fill="url(#prefix__paint2_linear_41:2516)"
      />
      <path
        d="M28.349 21.304V57.02c0 6.956-4.942 12.732-11.488 13.975-.865.186-1.791.248-2.718.248-7.843 0-14.205-6.397-14.205-14.286V21.304h28.41z"
        fill="#EFEFEF"
      />
      <path
        opacity={0.69}
        d="M119.138 30.621h-44.84c-1.173 0-2.16-.994-2.16-2.174v-2.981c0-1.18.987-2.174 2.16-2.174h44.84c1.173 0 2.161.994 2.161 2.174v2.92c0 1.241-.988 2.235-2.161 2.235z"
        fill="url(#prefix__paint3_linear_41:2516)"
      />
      <path
        opacity={0.69}
        d="M119.138 50h-44.84c-1.173 0-2.16-.994-2.16-2.174v-2.92c0-1.18.987-2.173 2.16-2.173h44.84c1.173 0 2.161.994 2.161 2.174v2.92c0 1.18-.988 2.173-2.161 2.173z"
        fill="url(#prefix__paint4_linear_41:2516)"
      />
      <path
        opacity={0.69}
        d="M119.138 69.379h-44.84c-1.173 0-2.16-.994-2.16-2.174v-2.92c0-1.18.987-2.173 2.16-2.173h44.84c1.173 0 2.161.994 2.161 2.174v2.919c0 1.18-.988 2.174-2.161 2.174z"
        fill="url(#prefix__paint5_linear_41:2516)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_41:2516"
          x1={201.867}
          y1={92.484}
          x2={-139.161}
          y2={92.484}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEE1E2" />
          <stop offset={1} stopColor="#B4BCBE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_41:2516"
          x1={35.495}
          y1={59.186}
          x2={35.495}
          y2={-37.066}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEE1E2" />
          <stop offset={1} stopColor="#B4BCBE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_41:2516"
          x1={48.632}
          y1={95.157}
          x2={48.632}
          y2={19.726}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEE1E2" />
          <stop offset={1} stopColor="#B4BCBE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear_41:2516"
          x1={140.274}
          y1={26.933}
          x2={-31.558}
          y2={26.933}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEE1E2" />
          <stop offset={1} stopColor="#B4BCBE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear_41:2516"
          x1={140.274}
          y1={46.318}
          x2={-31.558}
          y2={46.318}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEE1E2" />
          <stop offset={1} stopColor="#B4BCBE" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear_41:2516"
          x1={140.274}
          y1={65.703}
          x2={-31.558}
          y2={65.703}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEE1E2" />
          <stop offset={1} stopColor="#B4BCBE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgScript
