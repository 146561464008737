import React from "react";
import type { RouteObject } from "react-router-dom";
import { authPaths } from "./paths";

/* Authentication Routing */
const Login = React.lazy(() => import("../../views/modules/auth/login"));
const Activate = React.lazy(() => import("../../views/modules/auth/activate"));
const Recover = React.lazy(() => import("../../views/modules/auth/recover"));
const ResetPassword = React.lazy(
  () => import("../../views/modules/auth/recover/reset")
);

export const AuthRoutes: RouteObject[] = [
  { path: "/", element: <Login /> },
  { path: authPaths.subRoutes.login, element: <Login /> },
  { path: authPaths.subRoutes.activations, element: <Activate /> },
  { path: authPaths.subRoutes.recovery, element: <Recover /> },
  { path: authPaths.subRoutes.resetPassword, element: <ResetPassword /> },
  { path: "*", element: <Login /> },
];
