import { IDispatch } from "../helper";
import { parseError, parseJwt, saveSession } from "../../helpers";
import { EUser, IUserStore } from "./types";
import { api_login } from "server/auth";
import { IUserLogin } from "server/parameters/user";
import { toast } from "react-toastify";

export const login =
    (dispatch: IDispatch<IUserStore>) => async (params: IUserLogin) => {
        try {
            dispatch({ type: EUser.PENDING });
 
            const response = await api_login(params);
            const { accessToken, expiresIn } = response;
            const user = parseJwt(accessToken);         

            saveSession({ accessToken: accessToken, expiresIn: expiresIn });

            dispatch({ type: EUser.SAVE, user: { ...user, accessToken } });
            toast.success("Welcome!, Login successful")
        } catch (error: any) {
            dispatch({
                type: EUser.REJECTED,
                error: parseError(error),
            });
            toast.error(parseError(error))
        }
    };

export const logout = (dispatch: IDispatch<IUserStore>) => async () => {
    try {
        dispatch({ type: EUser.PENDING });
        localStorage.clear();
        dispatch({ type: EUser.LOGOUT });
    } catch (error) {
        dispatch({
            type: EUser.REJECTED,
            error: parseError(error),
        });
        toast.error(parseError(error))
    }
};

export const setErrorMessage =
    (dispatch: IDispatch<IUserStore>) =>
        async (message = "") => {
            try {
                dispatch({ type: EUser.SET_ERROR_MESSAGE, error: message });
            } catch (error) {
                dispatch({
                    type: EUser.REJECTED,
                    error: parseError(error),
                });
            }
        };

export const setLoading =
    (dispatch: IDispatch<IUserStore>) => async (loading: boolean) => {
        try {
            dispatch({ type: EUser.SET_LOADING, loading });
        } catch (error) {
            dispatch({
                type: EUser.REJECTED,
                error: parseError(error),
            });
        }
    };
