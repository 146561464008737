import createDataContext from "../createDataContext";
import { SettingsReducer } from "./reducer";
import * as SettingsAction from "./actions";
import { SETTINGS_STATE } from "./types";

export const { Provider: SettingsProvider, Context: SettingsContext } =
  createDataContext(
    "SETTINGS",
    SettingsReducer,
    SettingsAction,
    SETTINGS_STATE
  );
