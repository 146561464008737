import { IUserLogin } from "../../server/parameters/user";
import { EUserAuthorities } from "./authorities";
import { EUserRoles } from "./roles";

export const USER_STATE: IUserStore = {
    user: null,
    is_authenticated: false,
    error: "",
    loading: false,
};

export enum EUser {
    REJECTED,
    SET_LOADING,
    SAVE,
    SET_ERROR_MESSAGE,
    PENDING,
    LOGOUT,
}

export interface IUserStore {
    user: IUser | null;
    is_authenticated: boolean;
    error: string;
    loading: boolean;
    type?: EUser;
}

export interface IUser {
    lastName: string;
    resetPassword: boolean;
    user_name: string;
    authenticationDomain: string;
    roles: EUserRoles[];
    branch: string;
    authorities: EUserAuthorities[];
    panicMode: boolean;
    client_id: string;
    deviceStatus: string;
    firstName: string;
    aud: string[];
    scope: string[];
    name: string;
    userType: string;
    exp: number;
    jti: string;
    username: string;
    accessToken: string;
}
export interface IUserContext {
    state: IUserStore;
    setErrorMessage: (t: string) => any;
    login: (t: IUserLogin) => any;
    logout: () => any;
    setLoading: (t: boolean) => any;
}
