import { useContext, useEffect, useMemo } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { UserContext } from "context/user";
import { IUserContext } from "context/user/types";
import { DashboardRoutes } from "routes/dashboard/routes";
import { IStoreContext } from "context/store/types";
import { StoreContext } from "context/store";

const Dashboard = () => {
  const { state }: IUserContext = useContext(UserContext);
  const { getLGAs, state: { localGovernments } }: IStoreContext = useContext(StoreContext)
  const elements = useRoutes(DashboardRoutes);

  let navigate = useNavigate();

  useEffect(() => {
    const { is_authenticated } = state;
    if (is_authenticated) {
      if (!localGovernments.length) getLGAs({
        current: 0,
        pageSize: 800
      })
    } else {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, navigate]);

  return useMemo(() => elements, [elements]);
};

export default Dashboard;
