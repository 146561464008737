import { EStore, IStore } from "./types";

export const StoreReducer = (state: IStore, action: IStore): IStore => {
    switch (action.type) {
        case EStore.SET_GENERIC_ACTION_LOADING:
            return {
                ...state,
                actionsLoadingState: {
                    ...state.actionsLoadingState,
                    ...action.actionsLoadingState
                }
            };
        case EStore.SET_CRA_JOBS:
            return {
                ...state,
                CRAJobs: action.CRAJobs,
            };
        case EStore.SET_CRA_JOB_DETAILS:
            return {
                ...state,
                CRAJobDetails: action.CRAJobDetails
            }
        case EStore.SET_CRA_JOBS_SEARCH_PARAMS:
            return {
                ...state,
                CRAJobsSearchParams: action.CRAJobsSearchParams,
            }
        case EStore.SET_SUBTASK:
            return {
                ...state,
                subtask: action.subtask,
            }
        case EStore.SET_SUBTASK_PARAMS:
            return {
                ...state,
                subtaskParams: action.subtaskParams
            }
        case EStore.SET_LGAs:
            return {
                ...state,
                localGovernments: action.localGovernments,
            };
        case EStore.REJECTED:
            return {
                ...state,
            };
        default:
            return state;
    }
};
