import { SettingsProvider } from "context/settings";
import { StoreProvider } from "context/store";
import { UserProvider } from "context/user";
import { ToastContainer } from "react-toastify";
import React from "react";
import Modules from "views/modules";
import "./styles/index.css";
import "react-tabs/style/react-tabs.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <UserProvider>
      <SettingsProvider>
        <StoreProvider>
          <ToastContainer />
          <Modules />
        </StoreProvider>
      </SettingsProvider>
    </UserProvider>
  );
}

export default App;
