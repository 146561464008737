import { BUSINESS_OWNER_VERIFICATION_TYPE,  BUSINESS_VERIFICATION_TYPE,  GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE, SubtaskTypes } from "helpers/types"
import server from "server"
import { IAbortSubtaskParam, IApproveOrRejectCRAJobParam } from "./parameters/CRA"
import { IResponse } from "./responses"


export const api_get_CRA_jobs = async (searchParams: Record<string, any>, pagination: Record<string, any>) => {
    const response = await server.get(
        "/api/v1/credit-risk-assessments",
        {
            params: {
                page: pagination.current ? pagination.current : 0,
                size: pagination.pageSize ? pagination.pageSize : 10,
                ...searchParams,
            },
        }
    )

    return response.data as IResponse<any>
}

export const api_get_CRA_job_details = async (id: number | string) => {
    const response = await server.get(`/api/v1/credit-risk-assessments/${id}`)

    return response.data as IResponse<any>
}

export const api_approve_or_reject_CRA_job = async (payload: IApproveOrRejectCRAJobParam) => {
    await server.post(
        "/api/v1/credit-risk-assessments/approve",
        payload
    )
}

export const api_get_subtask = async (id: string, type: SubtaskTypes) => {
    const subtaskUrlMap = {
        [SubtaskTypes.BUSINESS_OWNER_VERIFICATION]: (id: string) => `/api/v1/business-owner-verifications/${id}`,
        [SubtaskTypes.BUSINESS_VERIFICATION]: (id: string) => `/api/v1/business-verifications/${id}`,
        [SubtaskTypes.CODEBTOR_VERIFICATION]: (id: string) => `/api/v1/business-loan-codebtor-verifications/${id}`,
        [SubtaskTypes.GUARANTOR_VERIFICATION]: (id: string) => `/api/v1/business-loan-guarantor-verifications/${id}`,
    }

    if (type in subtaskUrlMap) {
        const { data } = await server.get(subtaskUrlMap[type](id))
        return data as IResponse<any>
    }
}

export const api_submit_subtask = async<T>(data: T, type: SubtaskTypes) => {
    const subtaskUrlMap = {
        [SubtaskTypes.BUSINESS_OWNER_VERIFICATION]: "/api/v1/credit-risk-assessment-subtasks/business-owner/complete",
        [SubtaskTypes.BUSINESS_VERIFICATION]: "/api/v1/credit-risk-assessment-subtasks/business/complete",
        [SubtaskTypes.CODEBTOR_VERIFICATION]: "/api/v1/credit-risk-assessment-subtasks/codebtor/complete",
        [SubtaskTypes.GUARANTOR_VERIFICATION]: "/api/v1/credit-risk-assessment-subtasks/guarantor/complete",
    }

    if (type in subtaskUrlMap) {
        await server.post(
            subtaskUrlMap[type],
            data
        )
    }
}

export const api_submit_guarantor_verifications =
    async <T>(data: T, type: GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE) => {
    const verificationsUrlMap = {
        [GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE.IMAGE_VERIFICATION]: "/api/v1/credit-risk-assessment-subtasks/guarantor/biometric-auth",
        [GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE.AGREEMENT_EXECUTION]: "/api/v1/credit-risk-assessment-subtasks/guarantor/signed-agreement",
        [GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE.CONFIRM_OCCUPATION]: "/api/v1/credit-risk-assessment-subtasks/guarantor/confirm-occupation"
    }

    if (type in verificationsUrlMap) {
        await server.post(
            verificationsUrlMap[type],
            data
        )
    }
}

export const api_submit_codebtor_verifications =
    async <T>(data: T, type: GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE) => {
    const verificationsUrlMap = {
        [GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE.IMAGE_VERIFICATION]: "/api/v1/credit-risk-assessment-subtasks/codebtor/biometric-auth",
        [GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE.AGREEMENT_EXECUTION]: "/api/v1/credit-risk-assessment-subtasks/codebtor/signed-agreement",
        [GUARANTOR_OR_CODEBTOR_VERIFICATION_TYPE.CONFIRM_OCCUPATION]: "/api/v1/credit-risk-assessment-subtasks/codebtor/confirm-occupation"
    }

    if (type in verificationsUrlMap) {
        await server.post(
            verificationsUrlMap[type],
            data
        )
    }
}

export const api_submit_business_owner_verifications =
    async <T>(data: T, type: BUSINESS_OWNER_VERIFICATION_TYPE) => {
    const verificationsUrlMap = {
        [BUSINESS_OWNER_VERIFICATION_TYPE.CONFIRM_ADDRESS]: "/api/v1/credit-risk-assessment-subtasks/business-owner/confirm-residential-address",
        [BUSINESS_OWNER_VERIFICATION_TYPE.IMAGE_VERIFICATION]: "/api/v1/credit-risk-assessment-subtasks/business-owner/biometric-auth",
        [BUSINESS_OWNER_VERIFICATION_TYPE.AGREEMENT_EXECUTION]: "/api/v1/credit-risk-assessment-subtasks/business-owner/signed-agreement"
    }

    if (type in verificationsUrlMap) {
        await server.post(
            verificationsUrlMap[type],
            data
        )
    }
}

export const api_submit_business_verifications =
    async <T>(data: T, type: BUSINESS_VERIFICATION_TYPE) => {
    const verificationsUrlMap = {
        [BUSINESS_VERIFICATION_TYPE.CONFIRM_ADDRESS]: "/api/v1/credit-risk-assessment-subtasks/business/confirm-address",
        [BUSINESS_VERIFICATION_TYPE.CONFIRM_OPERATIONS]: "/api/v1/credit-risk-assessment-subtasks/business/confirm-inventory",
    }

    if (type in verificationsUrlMap) {
        await server.post(
            verificationsUrlMap[type],
            data
        )
    }
}

export const api_abort_subtask = async(data: IAbortSubtaskParam) => {
    await server.post(
        "/api/v1/credit-risk-assessment-subtasks/abort",
        data
    )
}
