import { createContext, useEffect, useReducer, FC } from "react";

const createDataContext = (
  name: string,
  reducer: any,
  actions: any,
  defaultValue: any
) => {
  const Context = createContext(defaultValue);
  const excludes = ["SETTINGS", "STORE"];
  let STORED_VALUE: any = null;

  if (!excludes.includes(name)) STORED_VALUE = localStorage.getItem(name);
  if (STORED_VALUE) STORED_VALUE = JSON.parse(STORED_VALUE);

  const Provider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, STORED_VALUE || defaultValue);
    const boundActions: any = {};

    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    useEffect(() => {
      if (!excludes.includes(name))
        localStorage.setItem(name, JSON.stringify(state));
    }, [state]);

    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};

export default createDataContext;
